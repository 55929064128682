import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';

import InfiniteScroll from 'react-infinite-scroll-component';

import { AppStateContext } from '../../../..';
import { getModulePath } from '../../HeaderService';
import { EDStateContext } from '../../../../context/EdState';
import { contractChanged } from '../../../../utils/licenseController';
import { getFirstandLastName, getStatusIcon, getTicketId } from '../../../../utils/utils';
import { getProjectDetails, getProjectList } from '../../../../pages/projects/ProjectService';

import repeatIcon from '../../../../images/repeat.svg';
import loaderGif from '../../../../images/loadBoxes.gif';
import bellIcon from '../../../../images/notifications.svg';
import rightArrowIcon from '../../../../images/right-arrow.svg';
import roleIcon from '../../../../images/notification/role.svg';
import statusIcon from '../../../../images/notification/status.svg';
import commentIcon from '../../../../images/notification/comment.svg';
import calendarIcon from '../../../../images/notification/calendar.svg';

const AllNotification = ({ allNotifications, allNotifyCount, handleScroll, onNotificationClick }) => {
  const navigate = useNavigate();

  const { personList } = React.useContext(EDStateContext);
  const { language: lang, appState } = React.useContext(AppStateContext);

  const allProject = appState.get('allDb', 'projects');

  /**
   * Generates the operation type (assign or revoke) based on the changes in old and new values.
   *
   * @param {Object} operation - The operation object containing old and new values.
   * @param {number} index - The index of the specific item in the operation's oldValues and newValues arrays.
   * @returns {Object|null} An object indicating the operation type (assign or revoke) and the corresponding value.
   */
  const generateSICOperation = (operation, index) => {
    const oldVals = operation.oldValues[index] || [];
    const newVals = operation.newValues[index] || [];

    // If oldVals is empty and newVals has values, it's an assign operation
    if (oldVals.length === 0 && newVals.length > 0) {
      return { type: 'assign', value: newVals[0] };
    }

    // If newVals is empty and oldVals has values, it's a revoke operation
    if (newVals.length === 0 && oldVals.length > 0) {
      return { type: 'revoke', value: oldVals[0] };
    }

    // If both oldVals and newVals have values, check for revokes and assigns
    for (const oldValue of oldVals) {
      if (!newVals.includes(oldValue)) {
        return { type: 'revoke', value: oldValue };
      }
    }

    for (const newValue of newVals) {
      if (!oldVals.includes(newValue)) {
        return { type: 'assign', value: newValue };
      }
    }

    // If no changes, return null or handle as needed
    return null;
  };

  /**
   * Retrieves the operation-based data for a given notification.
   * @param {Object} notification - The notification object.
   * @param {Array} notification.changedProperties - List of changed properties.
   * @param {Array} notification.newValues - List of new values for the changed properties.
   * @param {Array} notification.oldValues - List of old values for the changed properties.
   * @param {string} notification.module - The module type of the notification.
   * @param {string} notification.author - The author of the notification.
   * @param {string} notification.title - The title of the notification.
   * @returns {Object} The data object containing title, description, value, property, oldValue, and icon.
   * @author gaurav.rao
   */
  const getOperationBasedData = notification => {
    const { changedProperties, newValues, oldValues, module, author, title } = notification;
    const totalChangedItems = changedProperties.length;
    const newValue = newValues[totalChangedItems - 1];
    const oldValue = oldValues[totalChangedItems - 1];
    const property = changedProperties[totalChangedItems - 1];

    const data = {
      title: '',
      description: '',
      value: '',
      property,
      oldValue: '',
      icon: '',
    };

    /**
     * Generates the action texts for assigning/revoking roles.
     * @param {string} operationType - The operation type (assign/revoke).
     * @param {string} module - The module type.
     * @param {string} role - The role being changed.
     * @param {string} value - The value (new/old) of the role change.
     * @returns {Object} Object containing title and description.
     */
    const getSCIActionTexts = (operationType, module, role, value) => {
      const actionTexts = {
        assign: {
          'IB.EdBundle.Document.Ticket': {
            title: lang.notify.role_assigned,
            description: lang.notify[`${role}_assign_tckt`],
          },
          'IB.EdBundle.Document.Audit': {
            title: lang.notify.role_assigned,
            description: lang.notify[`${role}_assign_audit`],
          },
          'IB.EdBundle.Document.Project': {
            title: lang.notify.role_assigned,
            description: lang.notify[`${role}_assign_project`],
          },
        },
        revoke: {
          'IB.EdBundle.Document.Ticket': {
            title: lang.notify.unassign_ticket,
            description: lang.notify[`${role}_revoke_tckt`],
          },
          'IB.EdBundle.Document.Audit': {
            title: lang.notify.unassign_audit,
            description: lang.notify[`${role}_revoke_audit`],
          },
          'IB.EdBundle.Document.Project': {
            title: lang.notify.unassign_project,
            description: lang.notify[`${role}_revoke_project`],
          },
        },
      };
      const { title: actionTitle, description: actionDescription } = actionTexts[operationType][module];
      return {
        title: actionTitle,
        description: `${getFirstandLastName(value, appState, personList)} ${actionDescription}`,
      };
    };

    /**
     * Updates the data for role changes (assign/revoke).
     * @param {string} roleType - The role type (resp/acc).
     * @param {Object} data - The data object to be updated.
     */
    const updateRoleChange = (roleType, data) => {
      const moduleLang = module.includes('Ticket') ? 'tckt' : module.includes('Audit') ? 'audit' : 'project';

      data.value = newValue;
      data.icon = roleIcon;

      if (!oldValue || oldValue === 'null') {
        if (newValue && newValue !== 'null') {
          data.title = lang.notify.role_assigned;
          data.description = `${getFirstandLastName(newValue, appState, personList)} ${lang.notify[`${roleType}_assign_${moduleLang}`]}`;
        }
      } else if (!newValue || newValue === 'null') {
        data.title = lang.notify[`unassign_${moduleLang}`];
        data.description = `${getFirstandLastName(oldValue, appState, personList)} ${lang.notify[`${roleType}_revoke_${moduleLang}`]}`;
      } else {
        data.title = lang.notify[`unassign_${moduleLang}`];
        data.description = `${getFirstandLastName(oldValue, appState, personList)} ${lang.notify[`${roleType}_revoke_${moduleLang}`]}`;
      }
    };

    // Handling various property cases
    switch (property) {
      case 'consulted':
      case 'informed':
      case 'support':
        data.value = newValue;
        data.icon = roleIcon;
        const operationBasedData = generateSICOperation(notification, totalChangedItems - 1);
        if (operationBasedData) {
          const assignRevokeTexts = getSCIActionTexts(operationBasedData.type, module, property, operationBasedData.value);
          data.title = assignRevokeTexts.title;
          data.description = assignRevokeTexts.description;
        }
        break;

      case 'responsible':
      case 'accountable':
        updateRoleChange(property === 'responsible' ? 'resp' : 'acc', data);
        break;

      case 'comment':
        data.title = lang.notify.new_comment;
        data.value = newValue;
        data.icon = commentIcon;
        data.description = `${getFirstandLastName(author, appState, personList)} ${lang.notify[`add_comment_${module.includes('Ticket') ? 'tckt' : 'audit'}`]}`;
        break;

      case 'duedate':
      case 'endDate':
        data.title = newValue ? lang.notify.date_title : lang.m_lbl_due;
        data.value = newValue;
        data.icon = calendarIcon;
        data.description = newValue ? lang.notify[`date_${module.includes('Ticket') ? 'tckt' : module.includes('Audit') ? 'audit' : 'project'}`] : lang.notify.remove_due_date;
        break;

      case 'status':
        data.title = module.includes('Ticket') ? (oldValue ? lang.notify.status_change : lang.notify.new_ticket) : lang.notify.status_change_audit;
        data.value = newValue;
        data.oldValue = oldValue;
        data.icon = statusIcon;
        break;

      case 'archived':
        if (module === 'IB.EdBundle.Document.Project') {
          data.title = lang.notify.project_status_change;
          data.value = newValue;
          data.oldValue = oldValue;
          data.icon = statusIcon;

          const notificationKey = newValue ? 'project_archived' : 'project_dearchived';
          data.description = lang.notify[notificationKey].replace('__NAME__', title).replace('__AUTHOR__', getFirstandLastName(author, appState, personList));
        }
        break;
      case 'isRecurrent':
        if (module === 'IB.EdBundle.Document.Audit') {
          const freqText = {
            DAILY: lang.recurring_audit.day,
            WEEKLY: lang.recurring_audit.week,
            MONTHLY: lang.recurring_audit.month,
          };

          data.title = lang.recurring_audit.title;
          data.value = newValue;
          data.oldValue = oldValue;
          data.icon = repeatIcon;
          data.description = lang.notify.recurring_audit.replace('__INTERVAL__', newValue.interval).replace('__FREQ__', freqText[newValue.freq]);
        }
        break;
      default:
        break;
    }

    return data;
  };

  /**
   * Navigates to the detail page of a specific project module (Ticket, Audit, etc.)
   * after fetching and setting necessary project details in the application state.
   *
   * @param {string} module - The module type to navigate (e.g., Ticket, Audit).
   * @param {string} database - The database identifier for the project.
   * @param {string} id - The unique identifier of the specific item (e.g., ticket or audit).
   */
  const navigateToDetail = async (module: string, database: string, id: string, groupId: string, mapTemplateId: string) => {
    try {
      // Fetch project details from the database
      const project: any = await getProjectDetails(database, '', appState);

      // If no project details are found, exit the function
      if (!project) {
        return;
      }

      // Trigger any necessary onClick action
      onNotificationClick();

      // Retrieve all contracts and projects from the application state
      const allContracts = appState.get('allContr', 'contracts') || {};
      const allProjects = appState.get('allDb', 'projects');

      // Get contractId for the project from the allProjects object
      const contractId = allProjects[database]?.contract || null;

      const email = appState.get('id', 'user');
      const data = await getProjectList(email);
      appState.set('allDb', data.projects || {}, 'projects');
      appState.set('allContr', data.contracts || {}, 'contracts');

      // Update project with the contract ID if available
      project.contractId = contractId;

      // Apply any contract-related changes to the project
      contractChanged(allContracts, project, appState);

      // Generate the path for navigation based on the module type
      const modulePath = getModulePath(module, database, id, groupId, mapTemplateId);
      const role = appState.get('allDb.active.roles', 'projects');
      setTimeout(() => {
        if (role) {
          if (module === 'IB.EdBundle.Document.Project') {
            if (role.accountable || allContracts[contractId].admin) {
              // Navigate to the constructed path
              navigate(modulePath);
            } else {
              navigate(`/projects/${database}/tickets`);
            }
          } else {
            // Navigate to the constructed path
            navigate(modulePath);
          }
        }
      }, 1500);
    } catch (error) {}
  };

  /**
   * Renders a description for the notification based on the type of changed property.
   *
   * @param {Object} data - The data object containing description and status information.
   * @param {Object} notify - The notification object containing details about the changes.
   * @param {Array<string>} notify.changedProperties - List of properties that have changed.
   * @param {string} notify.status - The status of the notification.
   * @param {string} notify.title - The title related to the notification.
   * @returns {JSX.Element | null} - A JSX element describing the notification or null.
   * @author gaurav.rao
   */
  const renderDescription = (
    data: { description: string; value?: any; oldValue?: any },
    notify: { changedProperties: string[]; status: string; title?: string; module?: string },
    id: string,
  ): React.JSX.Element | null => {
    let { changedProperties, status, title, module } = notify;
    const property = changedProperties[changedProperties.length - 1];
    const formattedDate = data.value ? moment(data.value).format('DD/MM/YY') : '';

    if (!title) {
      title = getTicketId(id);
    }
    // Function to render common content for various property types
    const renderCommonContent = () => (
      <>
        {data.description}
        {property !== 'accountable' && property !== 'support' && module !== 'IB.EdBundle.Document.Project' && (
          <img src={getStatusIcon(status)} alt="" className="document-icon" />
        )}{' '}
        <span className="notification-link">{title || '-'}</span>
        {property === 'comment' && data.value && <span className="comment-desc" dangerouslySetInnerHTML={{ __html: data.value.note }} />}
      </>
    );
    // Function to render status change content
    const renderStatusChange = () => {
      if (module === 'IB.EdBundle.Document.Ticket' && !data.oldValue) {
        return (
          <>
            {lang.notify.new_ticket} &nbsp; <img src={getStatusIcon(data.value)} alt="" className="document-icon" />
            <span className="notification-link">{title || '-'}</span>
          </>
        );
      } else {
        return (
          <>
            {data.oldValue && (
              <>
                <img src={getStatusIcon(data.oldValue)} alt="" className="document-icon ml-0" />
                <img src={rightArrowIcon} alt="" className="document-icon" />
              </>
            )}
            <img src={getStatusIcon(data.value)} alt="" className="document-icon" />
            <span className="notification-link">{title || '-'}</span>
          </>
        );
      }
    };

    // Function to render content with a date
    const renderWithDate = () => (
      <>
        {data.description}
        {module !== 'IB.EdBundle.Document.Project' && <img src={getStatusIcon(status)} alt="" className="document-icon" />}{' '}
        <span className="notification-link">
          {title || '-'} {formattedDate && `is ${formattedDate}`}
        </span>
      </>
    );

    // Switch based on the type of property that has changed
    switch (property) {
      case 'duedate':
      case 'dueDate':
      case 'endDate':
        return renderWithDate();
      case 'status':
        return renderStatusChange();
      case 'archived':
        return <span>{data.description}</span>;
      case 'consulted':
      case 'informed':
      case 'responsible':
      case 'accountable':
      case 'support':
      case 'comment':
      case 'isRecurrent':
        return renderCommonContent();
      default:
        return null; // Handle unexpected property types
    }
  };

  /**
   * Renders an individual notification with relevant details and clickable functionality.
   *
   * @param {Object} notify - The notification object containing data to display.
   * @param {string} type - The type of notification (either 'read' or 'unread').
   * @returns {JSX.Element} - The rendered notification component.
   * @author gaurav.rao
   */
  const renderNotification = (notify, type) => {
    // Destructuring the notification object for easier access to properties
    const { module, database, _id, updatedAt, changedProperties, groupId, mapTemplateId} = notify;

    const data = getOperationBasedData(notify);

    const currentDb = allProject[database];
    const id = _id.split('|')[1];
    const helpText =
      module === 'IB.EdBundle.Document.Ticket' ? lang.notify.goto_ticket : module === 'IB.EdBundle.Document.Audit' ? lang.notify.goto_audit : lang.notify.goto_project;

    return (
      <Tooltip
        placement="bottom-end"
        PopperProps={{
          disablePortal: true,
        }}
        title={<span style={{ fontSize: '12px' }}>{helpText}</span>}
        arrow>
        <div className="notification-container" onClick={() => navigateToDetail(module, database, id, groupId, mapTemplateId)}>
          <div className="notification__left">
            {/* Show circle if notification is unread */}
            {type === 'unread' && <span className="circle"></span>}
          </div>
          <div className="notification__right">
            <div className="notification__title">
              <div>
                <img src={data.icon} alt="Role icon" />
                <span>{data.title}</span>
              </div>
              <span>{moment(updatedAt).format('DD/MM/YY')}</span>
            </div>
            <div className="notification__body">
              <div className="project-map-name">{currentDb?.projectName || '-'}</div>
              <p className="notification-info">{renderDescription(data, notify, id)}</p>

              {/* Show extra updates if more than one property changed */}
              {changedProperties.length > 1 && (
                <span className="notification-more">
                  +{changedProperties.length - 1} {lang.notify.more_updates}
                </span>
              )}
            </div>
          </div>
        </div>
      </Tooltip>
    );
  };

  return (
    <div id="scrollableDiv" className="notification-main">
      <InfiniteScroll
        dataLength={allNotifications.read.length + allNotifications.unread.length}
        next={handleScroll}
        hasMore={allNotifications.read.length + allNotifications.unread.length < allNotifyCount}
        scrollableTarget="scrollableDiv"
        loader={<img className="loader-lazy" src={loaderGif} alt="" />}>
        {allNotifications.unread.length > 0 && (
          <p className="or-divider">
            <span>{lang.m_lbl_unread}</span>
          </p>
        )}
        {allNotifications.unread.map(notify => renderNotification(notify, 'unread'))}
        {allNotifications.read.length > 0 && (
          <p className="or-divider">
            <span>{lang.m_lbl_read}</span>
          </p>
        )}
        {allNotifications.read.map(notify => renderNotification(notify, 'read'))}
      </InfiniteScroll>
      <div className="notification-end">
        <img src={bellIcon} alt="" />
        <p>{lang.notify.no_notify}</p>
      </div>
    </div>
  );
};

export default AllNotification;
